import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const Stories = () => {
  const [stories, setStories] = useState([]); // Initial state is an empty array
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation()

  //parse page from query parameters or default to 1
  const urlParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(parseInt(urlParams.get("page")) || 1);
  const [category, setCategory] = useState(urlParams.get("category") || 'All');

  useEffect(() => {
    fetchStories();
  }, [page, category]); // Dependency array is empty to run only once on component mount

  const fetchStories = async () => {
    setLoading(true);
    try {
      let query = `page=${page}&numStories=20`;
      if (category !== 'All') {
        query += `&category=${category}`;
      }
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/story?${query}`);

      console.log("fetched stories", res.data)
      setStories(res.data); 
    } catch (error) {
      console.error('Failed to fetch stories:', error);
    } finally {
      setLoading(false); //if data loads with no error 
    }
  };

  const handleNext = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    navigate(`/stories?page=${nextPage}`);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    const previousPage = page - 1;
    setPage(previousPage);
    if (previousPage === 1){
      navigate(`/stories`);
      window.scrollTo(0, 0);
    } else {
      navigate(`/stories?page=${previousPage}`);
      window.scrollTo(0, 0);
    }
  };

  const handleCategory = (event) => {
    console.log(event.target.value);
    setCategory(event.target.value);
    let query = `page=${page}`;
    if (category !== 'All') {
      query += `&category=${category}`;
    }
    navigate(`/stories?${query}`)
  };

  return (
    <div className='px-10 mt-12'>
      <h1 className='font-canela text-4xl pb-2'>Stories of Picturing Mexican America
      <select onChange={handleCategory} value={category} className='pt-2 mt-1 inline-block align-middle text-base font-canela float-right p-1 border border-gray-300 rounded'>
        <option value="All">All</option>
        <option value="Crime">Crime</option>
        <option value="Culture">Culture</option>
        <option value="Events">Events</option>
        <option value="Families">Families</option>
        <option value="Food">Food</option>
        <option value="Holidays">Holidays</option>
        <option value="Music">Music</option>
        <option value="People">People</option>
        <option value="Places">Places</option>
        <option value="Politics & Social Justice">Politics & Social Justice</option>
        <option value="Women & Gender">Women & Gender</option>
      </select>
      </h1>
    <div>

   </div>
      <div className='border-t border-gray-900 my-4 mb-12 w-auto'/>
      <div className='grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-y-4 gap-x-2 pb-10'>
        { !loading ? (
          stories.map((s) => (
            <button onClick={ ()=> navigate(`/stories/${s._id}`, {state: s})} key={s._id}>
              <div className='flex flex-col items-center'>
                <img src={s.imageUrls.length > 0 ? s.imageUrls[0] : null} alt="" className='max-h-48 w-auto'/>
                <h1 className='font-canela p-1'>{s.title || ''}</h1>
              </div>
            </button>
          ))
        ) : (
          <div className=' h-96'>
            <p>loading...</p>
          </div>
        )}
      </div>
      <div className='border-t border-gray-900 m mb-4 w-auto'/>
      <div className='flex justify-between pb-8'>
        {page === 1 ? (
          <div />
        ) : (
          <button onClick={handlePrevious} className='font-avenir text-gray-600 hover:underline'>PREVIOUS</button>
        )}
        {loading === false && stories.length < 20 ? (
          <div />
        ) : (
          <button onClick={handleNext} className='font-avenir text-gray-600 hover:underline'>NEXT</button>
        )}
      </div>
    </div>
  );
};

export default Stories;
